<template>
  <div>
    <el-button
      @click="openExportWorkflowData"
      type="primary"
    >Export <i class="el-icon-upload2"></i></el-button>

    <dialog-component
      :title="'Export ' + formbuilderDetails.name + `'s data`"
      :visible="exportTemplatesDataDialogVisible"
      @before-close="CloseExportWorkflowData"
       width="50%"
      :containerWidth="'50%'"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-loading="loading"
      element-loading-text="Downloading Data..."
    >
      <div class="DetailedView-Export">
        <el-input
        placeholder="Search Field"
        v-model="filterText"
      > </el-input>
      <el-scrollbar wrap-style="max-height: 400px;">
        <el-tree
          class="filter-tree"
          :data="templatesFields"
          :props="defaultProps"
          default-expand-all
          show-checkbox
          highlight-current
          :filter-node-method="filterNode"
          ref="tree"
          node-key="key"
          :default-checked-keys="checkedFields"
        >
        </el-tree>
      </el-scrollbar>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">Cancel</el-button>
        <el-button
          type="primary"
          @click="submitData"
        >Download</el-button>
      </span>
</div>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { groupBy } from "../../helpers/appHelper";
export default {
  props: ["formbuilderDetails", "templatesFields", "selectedFields"],
  components: {},
  data() {
    return {
      exportTemplatesDataDialogVisible: false,
      newEntityForm: {},
      sections: [],
      loading: false,
      laodingText: "",
      defaultProps: {
        children: "fields",
        id: "template_id",
        label: "label",
        table_display: "table_display"
      },
      filterText: "",
      checkedFields: []
    };
  },
  computed: {
    ...mapGetters("formbuilderData", [
      "getUsersFormbuilderTemplatesDataExportStatus"
    ])
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {
    // we have to group the primary fields
    if (this.selectedFields && this.selectedFields.length) {
      this.checkedFields = this.selectedFields;
    }
  },

  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    handleClose() {
      this.filterText = "";
      this.exportTemplatesDataDialogVisible = false;
    },
    openExportWorkflowData() {
      this.exportTemplatesDataDialogVisible = true;
      this.templates = groupBy(this.templatesFields, "template_id", true);
    },
    CloseExportWorkflowData(){
      this.exportTemplatesDataDialogVisible = false;
    },

    async submitData() {
      try {
        this.loading = true;

        this.exportTemplatesDataDialogVisible = true;

        let checked = this.$refs.tree.getCheckedNodes();
        checked = checked.filter(e => !e.fields);

        checked = checked.map(f => {
          return {
            label: f.label,
            template_name: f.template_title,
            template_id: f.template_id,
            entity_id: f.entity_type_id || null,
            key: f.key,
            input_type: f.input_type
          };
        });

        let params = {
          formbuilder_id: this.formbuilderDetails._id,
          fields: checked,
          name: this.formbuilderDetails.name
        };
        console.log("3wwwwwwww", params, checked);
        await this.$store.dispatch(
          "formbuilderData/exportUsersFormbuilderTemplatesData",
          params
        );
        if (this.getUsersFormbuilderTemplatesDataExportStatus) {
          this.loading = false;
          this.exportTemplatesDataDialogVisible = false;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while downloading data"
          });
          this.loading = false;
          this.exportTemplatesDataDialogVisible = false;
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.DetailedView-Export{
  margin: 20px;
}
</style>